import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import "./Radiology_Categories_Page.css"
import { useTranslation } from "react-i18next";
import Search_Bar from "../../components/Search_Bar";
import { PreparePage } from '../../utils/PageDataPrepare';
import { useLocation } from 'react-router-dom';
import SidebarCategoriesDisplay from '../../components/SidebarCategoriesDisplay';

export const Radiology_EntriesPerPage = 6;

const Radiology_Categories_Page = observer(() => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, Radiology_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user, user.search]);

    if (!pageData || !pageData?.subCategory)
        return;

    return <div className='page_content'>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/xray_icon.png' alt='page_title_icon'></img>{t('radiology_title')}
        </div>
        <div className='page_content_parent'>
            <div className='page_content_flex'>
                <SidebarCategoriesDisplay categories={pageData.subCategory} matchingCategories={pageData.search?.matches} />
            </div>
            <div className='page_content_rightpage_block'>
                <div className='Search_bar_block'>
                    <Search_Bar searchSucceeded={pageData.search?.succeeded} />
                </div>
                <div className='page_content_rightpage'>
                    <div className='page_content_title_info'>
                        {t('modelling_desc_main')}
                    </div>
                </div>
            </div>

        </div>
    </div>;
});

export default Radiology_Categories_Page;