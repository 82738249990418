import React, { useState, useEffect } from "react";
import OpenSeadragon from "openseadragon";
import "./DZI_ImageViewer.css"

//  TODO: необходимо добавить функциональность для получения dzi изображений в зашифрованном виде (см.: https://openseadragon.github.io/examples/advanced-data-model/)
export const DZI_ProtectedImageViewer = ({ images }) => {

    useEffect( () => {
        document.querySelectorAll('.openseadragon-container').forEach( (e) => e.remove() );

        OpenSeadragon({
            id: "openseadragon-viewer",
            prefixUrl: "/openseadragon/images/",
            tileSources: images, // Передача массива путей к изображениям
            sequenceMode: true, // Включение режима последовательности
            showReferenceStrip: true, // Отображение горизонтальной полосы прокрутки
        });
    }, [ images ] );

    return <div className='openseadragon' id='openseadragon-viewer'></div>;
};

export default DZI_ProtectedImageViewer;