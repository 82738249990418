import DZI_ImageViewer from './DZI_ImageViewer';
import DZI_ProtectedImageViewer from './DZI_ProtectedImageViewer';
import "./DZI_ImageViewer.css"

function DZI_Images_Display({images}) {

    return <DZI_ProtectedImageViewer images={images} />;
    //return <DZI_ImageViewer images={images}/>;
}

export default DZI_Images_Display;
